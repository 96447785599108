@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [class*="cursor-pointer"],
  a {
    @apply hover:opacity-80;
  }
  strong {
    @apply font-bold;
  }
  img {
    @apply pointer-events-none select-none;
  }
  video {
    max-height: 100%;
  }
}

@layer components {
  /* button pattern */
  .button-primary {
    @apply block whitespace-nowrap rounded-md bg-gradient-to-r from-purple-start to-purple-end text-center font-semibold tracking-wider text-white enabled:hover:from-purple-200 enabled:hover:to-purple-200 disabled:cursor-not-allowed disabled:from-blueGray-10 disabled:to-blueGray-10 disabled:text-blueGray-200;
    padding: var(--tw-custom-button-padding);
    font-size: var(--tw-custom-button-font-size);
    --tw-custom-button-padding: 16px;
    --tw-custom-button-font-size: 16px;
  }

  .button-primary-ghost {
    @apply block whitespace-nowrap rounded-md border border-purple-300 bg-white text-center font-semibold tracking-wider text-purple-300 enabled:hover:border-purple-200 enabled:hover:text-purple-200 disabled:cursor-not-allowed disabled:border-blueGray-10 disabled:bg-blueGray-10 disabled:text-blueGray-200;
    padding: var(--tw-custom-button-padding);
    font-size: var(--tw-custom-button-font-size);
    --tw-custom-button-padding: 16px;
    --tw-custom-button-font-size: 16px;
  }

  .button-secondary {
    @apply block whitespace-nowrap rounded-md bg-blueGray-700 text-center font-semibold tracking-wider text-white enabled:hover:bg-blueGray-200 disabled:cursor-not-allowed disabled:bg-blueGray-10 disabled:text-blueGray-200;
    padding: var(--tw-custom-button-padding);
    font-size: var(--tw-custom-button-font-size);
    --tw-custom-button-padding: 16px;
    --tw-custom-button-font-size: 16px;
  }

  .button-secondary-ghost {
    @apply block whitespace-nowrap rounded-md border border-blueGray-700 text-center font-semibold tracking-wider text-blueGray-700 enabled:hover:border-blueGray-100 enabled:hover:text-blueGray-200 disabled:cursor-not-allowed disabled:border-blueGray-10 disabled:bg-blueGray-10 disabled:text-blueGray-200;
    padding: var(--tw-custom-button-padding);
    font-size: var(--tw-custom-button-font-size);
    --tw-custom-button-padding: 16px;
    --tw-custom-button-font-size: 16px;
  }

  .button-tertiary {
    @apply block whitespace-nowrap rounded-md bg-transparent text-center font-semibold tracking-wider text-blueGray-700 hover:text-blueGray-200 disabled:cursor-not-allowed disabled:bg-transparent disabled:text-blueGray-100;
    padding: var(--tw-custom-button-padding);
    font-size: var(--tw-custom-button-font-size);
    --tw-custom-button-padding: 16px;
    --tw-custom-button-font-size: 16px;
  }

  .button-blur {
    @apply block whitespace-nowrap rounded-md border border-white bg-transparent text-center font-semibold tracking-wider text-white backdrop-blur-lg backdrop-filter disabled:cursor-not-allowed disabled:bg-transparent disabled:text-blueGray-100;
    padding: var(--tw-custom-button-padding);
    font-size: var(--tw-custom-button-font-size);
    --tw-custom-button-padding: 16px;
    --tw-custom-button-font-size: 16px;
  }

  .button-danger {
    @apply block whitespace-nowrap rounded-md bg-red-400 text-center font-semibold tracking-wider text-white enabled:hover:from-purple-200 enabled:hover:to-purple-200 disabled:cursor-not-allowed disabled:from-blueGray-10 disabled:to-blueGray-10 disabled:text-blueGray-200;
    padding: var(--tw-custom-button-padding);
    font-size: var(--tw-custom-button-font-size);
    --tw-custom-button-padding: 16px;
    --tw-custom-button-font-size: 16px;
  }

  .button-text {
    @apply block whitespace-nowrap rounded-md bg-white text-center tracking-wider text-blueGray-700 enabled:hover:border-blueGray-100 enabled:hover:text-blueGray-200 disabled:cursor-not-allowed disabled:border-blueGray-10 disabled:bg-blueGray-10 disabled:text-blueGray-200;
    padding: var(--tw-custom-button-padding);
    font-size: var(--tw-custom-button-font-size);
    --tw-custom-button-padding: 16px;
    --tw-custom-button-font-size: 16px;
  }

  /* button sizes */
  [class*="button"].button-tight {
    @apply rounded;
    --tw-custom-button-padding: 4px 10px;
    --tw-custom-button-font-size: 12px;
  }

  [class*="button"].button-small {
    @apply rounded;
    --tw-custom-button-padding: 8px 12px;
    --tw-custom-button-font-size: 14px;
  }

  [class*="button"].button-flexible {
    --tw-custom-button-min-width: auto;
  }

  .tag {
    @apply rounded-sm border border-blueGray-700 px-4 text-11 font-semibold text-blueGray-700;
  }

  .gradient-text {
    @apply bg-gradient-to-r from-purple-start to-purple-end bg-clip-text text-transparent;
  }

  .gradient-border {
    @apply border border-transparent from-purple-start to-purple-end bg-origin-border;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, var(--tw-gradient-stops));
    background-clip: padding-box, border-box;
  }

  .icon-gradient {
    fill: url(#icon-gradient);
  }

  .page-heading {
    @apply gradient-text text-24 font-bold tracking-wider;
  }

  .checkbox {
    @apply relative flex cursor-pointer appearance-none items-center justify-center rounded-full border border-blueGray-100 checked:border-purple-300 checked:bg-purple-300 checked:before:h-14 checked:before:w-14 checked:before:font-bold checked:before:text-white;
  }

  .checkbox::before {
    background-image: url('data:image/svg+xml;utf-8,<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.92704 9.488C4.82911 9.48792 4.73216 9.46854 4.64174 9.43096C4.55131 9.39338 4.46918 9.33835 4.40004 9.269L1.00004 5.882C0.859076 5.74157 0.779673 5.55089 0.779298 5.35191C0.778923 5.15294 0.857606 4.96196 0.998038 4.821C1.13847 4.68004 1.32915 4.60063 1.52812 4.60026C1.7271 4.59988 1.91808 4.67857 2.05904 4.819L4.93304 7.684L11.747 1.034C11.8895 0.894363 12.0815 0.817022 12.281 0.818991C12.4804 0.82096 12.6709 0.902078 12.8105 1.0445C12.9502 1.18692 13.0275 1.37898 13.0255 1.57842C13.0236 1.77787 12.9425 1.96836 12.8 2.108L5.45104 9.275C5.31093 9.41176 5.12283 9.48822 4.92704 9.488V9.488Z" fill="white"/> </svg> ');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .radio-button {
    @apply relative cursor-pointer appearance-none rounded-full border border-blueGray-100 checked:before:absolute checked:before:inset-0 checked:before:m-5 checked:before:flex checked:before:items-center checked:before:justify-center checked:before:rounded-full checked:before:bg-purple-300 checked:before:content-[''];
  }

  .form-label {
    @apply font-semibold tracking-wider text-blueGray-700;
  }

  .form-error-message {
    @apply font-medium text-red-400;
  }

  .page-back {
    @apply mx-auto mt-80 flex justify-center;
  }

  /* transition */
  .accordion {
    @apply transition-accordion duration-200 ease-linear;
  }

  .badge {
    @apply w-fit text-12 font-semibold;
  }

  .badge-speech-bubble {
    @apply relative w-fit text-12 font-semibold after:absolute after:bottom-[-8px] after:right-1/2 after:border-r-8 after:border-t-8 after:border-transparent after:content-[''];
  }

  .oshimen-bg {
    @apply absolute inset-0 scale-110 bg-cover blur-[150px] will-change-transform;
  }

  @media screen and (min-width: 1400px) {
    .oshimen-bg {
      /* TODO: TOPのレイアウト修正とともに直す */
      width: calc(100vw + ((100vw - 1216px) / 2));
      background-position-x: calc((100vw - 1216px) / 2);
    }
  }

  .page-top-space {
    @apply mt-26 2xl:mt-40;
  }

  .new-label {
    @apply before:absolute before:top-[-12px] before:rounded-[1px] before:bg-white before:px-2 before:pb-2 before:text-10 before:leading-none before:text-transparent before:content-['NEW'] after:absolute after:top-[-12px] after:bg-gradient-to-r after:from-purple-start after:to-purple-end after:bg-clip-text after:px-2 after:text-10 after:font-medium after:leading-[1.1] after:text-transparent after:content-['NEW'];
  }

  .gasha-ticket-quantity {
    @apply rounded-full bg-gasha-module px-16 py-4 text-14 font-semibold tracking-wider text-gasha-surface;
  }

  /* --- text --- */
  .with-horizontal-line {
    @apply flex items-center gap-8
    before:flex-1 before:border-t before:border-current before:content-['']
    after:flex-1 after:border-t after:border-current after:content-[''];
  }

}

@layer utilities {
  .text-shadow {
    text-shadow: 0px 0px 24px rgba(255, 255, 255, 0.75);
  }

  .grid-tight {
    @apply grid grid-cols-tight child:col-start-2 child:col-end-3;
  }

  .grid-main {
    @apply grid grid-cols-main child:col-start-2 child:col-end-3;
  }

  .grid-loose {
    @apply grid grid-cols-main child:col-start-2 child:col-end-3 md:grid-cols-loose;
  }

  .grid-drawer {
    @apply grid grid-cols-subWindowMain child:col-start-2 child:col-end-3 md:grid-cols-subWindowLoose;
    grid-template-rows: auto auto auto 1fr;
  }

  .contents-col {
    @apply 2xl:flex 2xl:justify-between;
  }

  .w-contents-main {
    @apply 2xl:max-w-720 2xl:flex-grow;
  }

  .gasha-swipe {
    --space-x: max(calc((100vw - 720px) / 2), 20px);
    @apply !px-[var(--space-x)] 2xl:!px-0;
  }

  .keep-all {
    word-break: keep-all;
  }

  .col-full-bleed {
    @apply col-start-1 -col-end-1;
  }

  .col-reset {
    @apply col-start-2 col-end-3;
  }

  .clip-isosceles-right-triangle {
    clip-path: polygon(0 0, 50% 50%, 100% 0);
  }

  .clip-triangle-bottom {
    clip-path: polygon(0 0, 50% 100%, 100% 0);
  }

  .link-underline {
    @apply relative pb-6 pr-12 text-14;
  }

  .link-underline:before {
    content: "";
    @apply absolute bottom-0 block h-2 w-full bg-gradient-to-r from-purple-start to-purple-end;
  }

  .link-underline:after {
    content: "";
    @apply absolute bottom-0 right-1 block h-2 w-8 origin-bottom-right rotate-45 bg-gradient-to-r from-purple-start to-purple-end;
  }

  .rich-text {
    @apply break-all leading-loose tracking-wider;
  }

  .rich-text a {
    @apply text-purple-300 underline;
  }

  .rich-text ul,
  .rich-text ol {
    margin-left: 1em;
  }

  .rich-text ul {
    @apply list-disc;
  }

  .rich-text ol {
    @apply list-decimal;
  }

  .hidden-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hidden-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .bg-gradient-ghost {
    @apply bg-gradient-to-b from-blueGray-10 to-blueGray-10/0;
  }

  .bg-gradient-purple {
    @apply bg-gradient-to-tl from-bgMainVisualOnTop-start via-bgMainVisualOnTop-middle to-bgMainVisualOnTop-end;
  }

  .bg-gradient-footer {
    background: linear-gradient(190deg, #fef8ff 30%, #f1efff 86%);
  }

  .animate-paused {
    animation-play-state: paused;
  }

  .scroll-mt-header {
    @apply scroll-mt-header-sp md:scroll-mt-header-pc;
  }

  /* gacha用 */
  .diagonal-bg {
    --skew: 5deg;
    --tan-alpha: tan(var(--skew));
    --skew-padding: calc(min(100vw, 720px) * var(--tan-alpha) / 2);
    position: relative;
    margin-top: calc(var(--skew-padding) * -1);
    padding-bottom: calc(var(--skew-padding, 0) + 68px);
    clip-path: polygon(
      0 0,
      100% calc(var(--skew-padding)),
      100% 100%,
      0 calc(100% - var(--skew-padding))
    );
  }

  .diagonal-top-bg {
    --skew: 11.5deg;
    --tan-alpha: tan(var(--skew));
    --skew-padding: calc(100vw * var(--tan-alpha) / 2);
    clip-path: polygon(
            0 0,
            100% var(--skew-padding),
            100% 100%,
            0 100%
    );
  }

  .diagonal-bottom-bg {
    --skew: 11.5deg;
    --tan-alpha: tan(var(--skew));
    --skew-padding: calc(100vw * var(--tan-alpha) / 2);
    clip-path: polygon(
            0 0,
            100% 0,
            100% 100%,
            0 calc(100% - var(--skew-padding))
    );
  }
}
